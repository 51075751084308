const questioncolumns = [
  {
    key: "id",
    label: "Question Id",
  },
   
  {
    key: "name",
    label: "Statement",
  },
  {
    key: "examName",
    label: "ExamName",
  },
  {
    key: "categoryName",
    label: "Category Name",
  },
  {
    key: "topicName",
    label: "Topic Name",
  },
  {
    key: "onOfOptions",
    label: "On Of Options",
  },
   
  {
    key: "correctAnswer",
    label: "correctAnswer",
  },
  {
    key: "actions",
    label: "Actions",
  },
];
export { questioncolumns };
