import { Image, Tooltip } from "@nextui-org/react";
import React from "react";
import { dashboardlogo } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { RiHomeLine } from "react-icons/ri";
import { IoIosNotificationsOutline } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { RiShutDownLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/slices/userSlice";
import { deleteCookie } from "../../hooks/useCookies";

const FirstSidebar = ({ setChoice, choice }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    deleteCookie("token");
    navigate("/");
  };
  return (
    <div className="setting-side-bar shadow-lg z-20 min-w-[60px] py-4 px-3   bg-white flex flex-col justify-between">
      <div>
        <div className="logo pb-3">
          <img
            src={dashboardlogo}
            alt={dashboardlogo}
            width={48}
            height={48}
            className="max-w-[50px] w-full"
          />
        </div>
        <div className="btn-list">
          <ul className="flex flex-col mt-5 gap-3 px-1">
            <Tooltip
              placement="right-start"
              content="Management "
              className="bg-themeBtn-0 text-white"
            >
              <li onClick={() => setChoice(1)}>
                <Link to="#">
                  <RiHomeLine
                    className={`w-full max-w-[80px] text-[2.50rem] ${
                      choice === 1
                        ? "bg-[#3F7FAE] text-white hover:text-themePrimary-0 rounded-lg "
                        : ""
                    }   py-1 px-1`}
                  />
                </Link>
              </li>
            </Tooltip>
            <Tooltip
              placement="right-start"
              content="Account setting"
              className="bg-themeBtn-0 text-white"
            >
              <li onClick={() => setChoice(2)}>
                <Link to="#">
                  <CiUser
                    className={`w-full max-w-[80px] text-[2.50rem] ${
                      choice === 2
                        ? "bg-[#3F7FAE] text-white hover:text-themePrimary-0 rounded-lg "
                        : ""
                    }   py-1 px-1`}
                  />
                </Link>
              </li>
            </Tooltip>
          </ul>
        </div>
      </div>
      <div>
        <Tooltip placement="right-start" content="Log Out" color="warning" className="text-white">
          <button onClick={handleLogout} className="h-full">
            <RiShutDownLine 
              className={`w-full h-full text-themeBtn-0    max-w-[60px] text-[2rem]    py-1 px-1`}
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default FirstSidebar;
