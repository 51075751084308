const newslettercolumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "submiited",
    label: "Date",
  },
];

export { newslettercolumn };
