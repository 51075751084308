const itemcount = [
    {
        key: 10,
        label: "10",
      },
      {
        key: 20,
        label: "20",
      },
      {
        key: 30,
        label: "30",
      },
];

export { itemcount };