const feedbackcolumns = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "detail",
    label: "Feed Back",
  },
  {
    key: "questionId",
    label: "Question Id",
  },
  {
    key: "userName",
    label: "User Name",
  },
  {
    key: "ip",
    label: "Ip",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "star",
    label: "Star",
  },
  {
    key: "actions",
    label: "Actions",
  },
];

export { feedbackcolumns };
