import React, { useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  useDisclosure,
  Tooltip,
} from "@nextui-org/react";

const DeleteModal = ({ handleDelete, id, open, setDeleteModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (open) {
      onOpen();
    }
  }, [open]);

  const handleClose = () => {
    setDeleteModal(false);
    onClose();
  };

  const handleFun = async () => {
    await handleDelete(id);
    setDeleteModal(false);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalHeader></ModalHeader>
          <h3 className="font-bold text-lg  text-center uppercase">
            Please Confirm to delete
          </h3>
          <ModalFooter>
            <Button color="danger" variant="light" onPress={handleClose}>
              Close
            </Button>
            <Button className="bg-red-600 text-white" onPress={handleFun}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;
