export const CodeType = [
    {
      id: "fixed",
      key: "fixed",
      name: "fixed",
    },
    {
      id: "percentage",
      key: "percentage",
      name: "percentage",
    },
  ];
 export  const PromoUsage = [
    {
      id: "one",
      key: "one",
      name: "one",
    },
    {
      id: "more",
      key: "more",
      name: "more",
    },
  ];