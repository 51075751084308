const blogsColumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "name",
    label: "Title",
  },
  {
    key: "shortDescription",
    label: "Short Discription",
  },
  {
    key: "date",
    label: "Created At",
  },

  {
    key: "actions",
    label: "Actions",
  },
];

export { blogsColumn };
