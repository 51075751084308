import React, { useEffect, useState } from "react";
import { Button, Input } from "@nextui-org/react";
import { Controller } from "react-hook-form";
import { FaMinusCircle } from "react-icons/fa";

const CustomRadio = ({ value, checked, onChange, children }) => {
  return (
    <label className="custom-radio px-2 py-2">
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className="hidden"
      />
      <span className={`radio-mark ${checked ? "checked" : ""}`}></span>
      {children}
    </label>
  );
};

const EditRadioOptions = ({
  defaultValue,
  name,
  control,
  setOptions,
  options,
}) => {
  const [newOption, setNewOption] = useState("");
  const [selected, setSelected] = useState(null);

  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      const updatedOptions = options.map((option) => ({
        ...option,
        isCorrect: option.name === selected,
      }));

      updatedOptions.push({
        name: newOption.trim(),
        isCorrect: false,
      });

      setOptions(updatedOptions);
      setNewOption("");
    }
  };

  const checkRadio = (checkOption) => {
    const updatedOptions = options.map((option) => ({
      ...option,
      isCorrect: option.name === checkOption,
    }));

    setOptions(updatedOptions);
  };

  useEffect(() => {
    checkRadio(selected);
  }, [selected]);

  const [correctOption, setCorrectOption] = useState(null);

  useEffect(() => {
    for (const option of options) {
      if (option.isCorrect) {
        setCorrectOption(option.name);
        setSelected(option.name);
        break;
      }
    }
  }, [options]);

  const isButtonDisabled = newOption.trim() === "";

  const handleDeleteOption = (index) => {
    const updatedOptions = [...options];
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
  };

  const [checkdefault, setCheckdefault] = useState(null);

  useEffect(() => {
    if (defaultValue[0]?.isCorrect) {
      setCheckdefault(defaultValue[0]?.name);
      setSelected(defaultValue[0]?.name);
    }
  }, [defaultValue]);

  return correctOption && checkdefault ? (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div
            {...field}
            className="radio-group"
            onChange={(e) => setSelected(e.target.value)}
          >
            {options.map((option, index) => (
              <div
                key={index}
                className="w-full grid grid-cols-5  rounded-sm"
              >
                <div className="col-span-4   bg-gray-100  my-2 rounded-md">
                  <CustomRadio
                    value={option.name}
                    checked={selected === option.name}
                    onChange={() => setSelected(option.name)}
                  >
                    {option.name}
                  </CustomRadio>
                </div>
                {!option?.id && (
                  <div className="w-{20%} flex justify-end">
                    <FaMinusCircle
                      className="ml-2 text-red-500 text-lg cursor-pointer"
                      onClick={() => handleDeleteOption(index)}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      />

      <div className="flex items-end gap-4 mt-4">
        <Input
          placeholder="Enter option name"
          variant="bordered"
          color={"#00000"}
          size="lg"
          radius="md"
          defaultValue={defaultValue}
          value={newOption}
          classNames={{
            inputWrapper: [
              "bordered",
              "border-1",
              "border-[#EBEBEB]",
              "hover:border-[#3F7FAE]",
              "custom-input-design",
            ],
          }}
          onChange={(e) => setNewOption(e.target.value)}
        />
        <Button
          onClick={handleAddOption}
          auto
          className={`w-full text-white text-sm font-medium py-6 border ${
            isButtonDisabled
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-themeBtn-0"
          } md:w-[20%]`}
          disabled={isButtonDisabled}
        >
          Add Option
        </Button>
      </div>
    </div>
  ) : null;
};

export default EditRadioOptions;
