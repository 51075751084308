const promocodescolumn = [
    {
        key: "num",
        label: "SN.",
      },
      {
        key: "name",
        label: "Name",
      },
      {
        key: "exam",
        label: "Exam",
      },
      {
        key: "category",
        label: "Category",
      },
      {
        key: "actions",
        label: "Actions",
      },
];
export { promocodescolumn };