import React, { useState } from "react";
import InputField from "../../components/general/InputField";
import { Logo } from "../../assets";
import { Image } from "@nextui-org/react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../components/general/ButtonComponent";
import { API } from "../../api";
import { errorToast, successToast } from "../../hooks/useToast";
import { setCookie } from "../../hooks/useCookies";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../validations/login";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../store/slices/userSlice";

const LogIn = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginSchema) });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await API.adminLogin(data);
      successToast(response?.data?.message);
      setLoading(false);
      setCookie("token", response?.data?.data?.token);
      dispatch(setUser(response?.data?.data));
      navigate("/dashboard/");
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not log In at the moment");
    }
  };

 

  return (
    <div className="login-page h-[100vh] w-full ">
      <div className="container">
        <div className="container  mx-auto py-20 px-2.5 sm:px-0 h-[100vh] w-full flex  justify-center items-center">
          <div className="w-[360px] max-w-[750px] min- mx-auto ">
            <div className="logo-area flex flex-wrap justify-center mb-8">
              <Image src={Logo} alt={Logo} className="w-full" />
            </div>

            <div className="form-area">
              <h1 className="text-center text-3xl sm:text-4xl font-normal mb-7">
                Admin Login
              </h1>
              <form
                className="grid grid-col-1 gap-6"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputField
                  label="User Name"
                  type="text"
                  isInvalid={isInvalid}
                  isRequired={true}
                  placeholder="Enter User Name"
                  errors={errors}
                  errortext="Please Enter User Name"
                  name="userName"
                  register={register}
                />
                <InputField
                  label="Password"
                  type="password"
                  isInvalid={isInvalid}
                  isRequired={true}
                  placeholder="Enter Password"
                  errors={errors}
                  errortext="password is required"
                  name="password"
                  register={register}
                />
                <div className="w-full md:w-3/6 mx-auto">
                  <ButtonComponent
                    type={"submit"}
                    text="Login"
                    loading={loading}
                    isActive={true}
                  />
                  {/* <Link
                    to="/forgot-password"
                    className="underline text-center table w-full mt-4 text-themePrimary-0 hover:text-themeSecondry-0 font-normal"
                  >
                    Forgot Password
                  </Link> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
