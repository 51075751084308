const tablecolumns = [
  {
    key: "id",
    label: "Order#",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "price",
    label: "Price",
  },
  {
    key: "subscribeDate",
    label: "Subscription Date",
  },
  {
    key: "endDate",
    label: "End Date",
  },
  {
    key: "country",
    label: "Country",
  },
  {
    key: "plan",
    label: "Plan",
  },
   
  
];

export { tablecolumns };
