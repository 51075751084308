const allmembercolumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "country",
    label: "Country",
  },
  {
    key: `lastLogin`,
    label: "Recent log in",
  },
  {
    key: `totalExams`,
    label: "Attempted Exams",
  },
  {
    key: "date",
    label: "Joined At",
  },
  {
    key: "otpVerified",
    label: "Verification Status",
  },
  {
    key: "isBlocked",
    label: "Account Status",
  },
  {
    key: "actions",
    label: "Actions",
  },
];

export { allmembercolumn };
